<template>
  <!-- 订单管理 —— 订单申诉 -->
  <div class="box-card">
    <div style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="停车场：">
          <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
            placeholder="请选择停车场" @change="onSubmit">
            <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-input placeholder="请输入车牌号" maxlength="8" :value="queryForm.plate_number"
            @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>

        <el-form-item label="订单状态：">
          <el-select popper-class="my-select" clearable v-model="queryForm.status" placeholder="请选择订单状态"
            @clear="queryForm.status = null; onSubmit()" @change=" onSubmit ">
            <el-option v-for=" item  in  getOptions('Status') " :key=" item.value " :label=" item.name "
              :value=" item.value "></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="申诉账号：">
          <el-input maxlength="15" placeholder="请输入申诉账号" :value=" queryForm.appeal_account "
            @input=" (e) => (queryForm.appeal_account = validSpace(e)) " @keyup.enter.native=" onSubmit "></el-input>
        </el-form-item>

        <el-form-item>
          <lbButton icon="chaxun" :fill=" true " @click=" onSubmit() ">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="订单申诉">
        <template slot="heard-right">
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
           <!-- 使用插件生产表格 -->
           <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="parking_time_length">
              {{ getTimeLength(row.parking_time_length) }}
            </template>
            <template slot-scope="{row}" slot="calculate_cost">
              {{ '￥' + row.calculate_cost.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="status">
              <span>{{ getWayName("Status", row.status) }}</span>
            </template>
            <template slot-scope="{row}" slot="appeal_time">
              <span>{{ validDateTime(row.appeal_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="err" icon="daichuli" hint="处理" @click=" showReviewDialog(row) "
                  v-if=" row.status === 1 && (isShowBtn('9b01f444f9844dfca4a513ec') || isShowBtn('d3f45fc8704047a185253520')) ">
                </lbButton>
                <lbButton type="succes" icon="chakan" hint="查看" @click=" lookit(row) " v-if=" row.status === 2 ">
                </lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data=" tableData " align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="order_no" label="订单号" width="150"></el-table-column>
            <el-table-column prop="plate_number" label="车牌号"></el-table-column>
            <el-table-column prop="parking_name" label="停车场"></el-table-column>
            <el-table-column prop="parking_time_length" label="停车时长">
              <template slot-scope="scope">{{ getTimeLength(scope.row.parking_time_length) }}</template>
            </el-table-column>
            <el-table-column prop="calculate_cost" label="停车费用">
              <template slot-scope="scope">{{ '￥' + scope.row.calculate_cost.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="appeal_account" label="申诉账号"></el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span>{{ getWayName("Status", scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="申诉时间" width="160">
              <template slot-scope="scope">
                <span>{{ validDateTime(scope.row.appeal_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="90">
              <template slot-scope="scope">
                <lbButton type="err" icon="daichuli" hint="处理" @click=" showReviewDialog(scope.row) "
                  v-if=" scope.row.status === 1 && (isShowBtn('9b01f444f9844dfca4a513ec') || isShowBtn('d3f45fc8704047a185253520')) ">
                </lbButton>
                <lbButton type="succes" icon="chakan" hint="查看" @click=" lookit(scope.row) " v-if=" scope.row.status === 2 ">
                </lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change=" handleSizeChange " @current-change=" handleCurrentChange "
            :current-page=" queryForm.PageIndex " :page-sizes=" [10, 20, 30, 40] " :page-size=" queryForm.PageSize "
            layout="total, sizes, prev, pager, next, jumper" :total=" TotalCount " ></el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog append-to-body title="处理" :isShow=" dialogaReview " @close=" closeDialog "
      footerSlot>
      <el-form :model=" formreview " ref="formreview" :rules=" formreviewRules ">
        <el-form-item label="申诉原因：" :label-width=" formLabelWidth " style="margin-bottom: 0px;">
          <div style="width: 100%; text-align: start">
            <span>{{ dialogItem? dialogItem.appeal_msg : "" }}</span>
          </div>
        </el-form-item>
        <el-form-item label :label-width=" formLabelWidth " style="margin-bottom: 0px;">
          <div class="imagediv">
            <div v-for="( item, index ) of  dialogItem.appeal_img " :key=" index " style="margin-right:30px;">
              <el-image style="width: 100%; height: 100%;" fit="contain" :lazy=" true " :z-index=" 10000 " :src=" item "
                :preview-src-list=" dialogItem.appeal_img "></el-image>
            </div>
          </div>
          <!-- <div class="imagediv">
            <img v-for="(item, index) of dialogItem.appeal_img" :key="index" :style="imageWidth" class="imgprove" :src="item" />
          </div>-->
        </el-form-item>
        <el-form-item label="停车费用：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align:start;">
          <span>￥{{ dialogItem.calculate_cost ? dialogItem.calculate_cost.toFixed(2) : '0.00' }}</span>
        </el-form-item>
        <el-form-item label="支付状态：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align:start;">
          <span>{{ dialogItem.orderStatus }}</span>
        </el-form-item>
        <el-form-item v-if=" dialogItem.orderStatus === '待支付' " prop="deal_method" label="待支付费用："
          :label-width=" formLabelWidth " style="margin-bottom:20px;">
          <div style="display:flex;">
            <el-select popper-class="my-select" v-model=" formreview.deal_method " style="margin-right:15px;">
              <el-option label="保持不变" :value=" 3 "></el-option>
              <el-option label="费用调整为" :value=" 0 "></el-option>
            </el-select>
            <el-form-item prop="final_cost" v-if=" formreview.deal_method == 0 ">
              <el-input maxlength="8" style="width: 150px" v-model=" formreview.final_cost "
                @input=" (e) => (formreview.final_cost = floatNumber(e)) " placeholder="请输入调整金额"></el-input>
              <span style="margin-left:5px;">元</span>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item prop="deal_with_msg" label="处理意见：" :label-width=" formLabelWidth ">
          <el-input class="handleinput" rows="5" type="textarea" v-model=" formreview.deal_with_msg "
            @input=" (e) => (formreview.deal_with_msg = validSpecialChar(e)) " placeholder="请详细说明审核原因..."></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <lbButton type="goBack" icon="back" @click=" closeReviewDialog(2) " v-if=" dialogItem.orderStatus === '已支付' ">拒 绝</lbButton>
        <lbButton type="orign" icon="confirm" @click=" closeReviewDialog(1) ">
          {{
          dialogItem.orderStatus === '待支付' ? '确定处理' :
          '同意并退款'
          }}
        </lbButton>
      </div>
    </lebo-dialog>

    <lebo-dialog append-to-body title="详情" :isShow=" dialogaDetail "
      @close=" dialogaDetail = false " closeOnClickModal footerSlot>
      <el-form>
        <el-form-item label="申诉原因：" :label-width=" formLabelWidth " style="margin-bottom: 0px;">
          <div style="width: 100%; text-align: start">
            <span>{{ dialogItem.appeal_msg }}</span>
          </div>
        </el-form-item>
        <el-form-item label :label-width=" formLabelWidth " style="margin-bottom: 0px;">
          <div class="imagediv">
            <div v-for="( item, index ) of  dialogItem.appeal_img " :key=" index " style="margin-right:30px;">
              <el-image style="width: 100%; height: 100%;" fit="contain" :lazy=" true " :z-index=" 10000 " :src=" item "
                :preview-src-list=" dialogItem.appeal_img "></el-image>
            </div>
          </div>
          <!-- <div class="imagediv">
            <img v-for="(item, index) of dialogItem.appeal_img" :key="index" :style="imageWidth" class="imgprove" :src="item" />
          </div>-->
        </el-form-item>
        <!-- <el-form-item label="原停车费用：" :label-width="formLabelWidth" style="text-align: start" v-if="dialogItem.orderStatus === '已支付'">
          <span>￥{{dialogItem.calculate_cost}}</span>
        </el-form-item>
        <el-form-item label="现停车费用：" :label-width="formLabelWidth" style="text-align: start" v-if="dialogItem.orderStatus === '已支付'">
          <span>￥{{dialogItem.final_cost}}</span>
        </el-form-item>-->
        <el-form-item label="停车费用：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align: start">
          <span>￥{{ dialogItem.calculate_cost ? dialogItem.calculate_cost.toFixed(2) : '0.00' }}</span>
        </el-form-item>
        <el-form-item label="支付状态：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align: start">
          <span>{{ dialogItem.orderStatus }}</span>
        </el-form-item>
        <el-form-item label="处理方式：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align: start">
          <span>{{ orderHandleWay(dialogItem.deal_method) }}</span>
        </el-form-item>
        <el-form-item label="待支付费用：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align: start"
          v-if=" dialogItem.orderStatus === '待支付' ">
          <span>
            {{
            dialogItem.deal_method === 3 ? '保持不变' : (dialogItem.deal_method === 1 ? `费用调整为${
              dialogItem.final_cost
              ? dialogItem.final_cost.toFixed(2) : '0.00'
            } 元` : '')
            }}
          </span>
        </el-form-item>
        <el-form-item label="处理时间：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align: start">
          <span>{{ validDateTime(dialogItem.deal_with_time) }}</span>
        </el-form-item>
        <el-form-item label="处理人：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align: start">
          <span>{{ dialogItem.username }}</span>
        </el-form-item>
        <el-form-item label="处理意见：" :label-width=" formLabelWidth " style="margin-bottom: 0px;text-align: start">
          <span>{{ dialogItem.deal_with_msg }}</span>
        </el-form-item>
      </el-form>
    </lebo-dialog>
  </div>
</template>

<script>
import request from '@/api/orderApi'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      parking_name: '',
      queryForm: {
        parking_id: null,
        plate_number: '',
        status: null,
        appeal_account: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      dialogaReview: false,
      formreview: {
        _id: '',
        deal_with_msg: '',
        deal_with_result: '',
        final_cost: '',
        deal_method: 3
      },
      formreviewRules: {
        deal_with_msg: [
          { required: true, message: '请输入审核说明', trigger: 'blur' }
        ],
        deal_method: [
          { required: true, message: '请选择处理方式', trigger: 'blur' }
        ],
        final_cost: [{ required: true, message: '请输入金额', trigger: 'blur' }]
      },
      dialogaDetail: false,
      formLabelWidth: '120px',
      dialogItem: {},
      TotalCount: 0,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '订单号',
            prop: 'order_no',
            width: 150
          }, {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '停车时长',
            prop: 'parking_time_length',
            slot: true
          }, {
            label: '停车费用',
            prop: 'calculate_cost',
            slot: true
          }, {
            label: '申诉账号',
            prop: 'appeal_account'
          }, {
            label: '订单状态',
            prop: 'status',
            slot: true
          }, {
            label: '申诉时间',
            prop: 'appeal_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () { },
  computed: {
    ...mapState(['parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName']),
    imageWidth () {
      if (this.dialogItem.appeal_img && this.dialogItem.appeal_img.length > 0) {
        var w = parseInt(100 / this.dialogItem.appeal_img.length)
        return {
          width: `${w}%`
        }
      } else {
        return {
          width: '100%'
        }
      }
    }
  },
  mounted () {
    this.requestPorkingList()
    this.requestAppealList()
  },
  methods: {
    ...mapActions(['requestPorkingList']),
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestAppealList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestAppealList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestAppealList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestAppealList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestAppealList()
    // },
    lookit (row) {
      this.requestOrderStatus(row._id, 2)
    },
    async requestAppealList () {
      const res = await request.orderApplyList({
        parking_id: this.queryForm.parking_id,
        plate_number: this.queryForm.plate_number,
        status: this.queryForm.status ? this.queryForm.status : null,
        appeal_account: this.queryForm.appeal_account,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    async requestReview () {
      try {
        const params = this.deepClone(this.formreview)
        if (this.dialogItem.orderStatus !== '待支付') {
          params.final_cost = 0
        } else {
          params.final_cost = parseFloat(this.formreview.final_cost)
        }
        await request.appealAudit(params)
        this.dialogaReview = false
        this.requestAppealList()
      } catch (error) { }
    },
    closeReviewDialog (type) {
      const that = this
      this.$refs.formreview.validate(valid => {
        if (valid) {
          that.formreview._id = that.dialogItem._id
          if (that.dialogItem.orderStatus === '待支付') {
            that.formreview.deal_with_result =
              that.formreview.deal_method === 3 ? 2 : 1
            that.formreview.final_cost =
              that.formreview.deal_method === 3
                ? 0
                : that.formreview.final_cost
          } else if (that.dialogItem.orderStatus === '已支付') {
            that.formreview.deal_with_result = type
            that.formreview.deal_method = type === 2 ? 3 : 0
          }
          // that.formreview.deal_with_result = that.dialogItem.orderStatus === '待支付' ? 2 : type;
          // that.formreview.final_cost = that.formreview.deal_method === 3 ? that.formreview.final_cost = 0 : that.formreview.final_cost
          that.requestReview()
        } else {
          return false
        }
      })
    },
    closeDialog () {
      var that = this
      this.dialogaReview = false
      this.$nextTick(() => {
        that.$refs.formreview.resetFields()
      })
    },
    showReviewDialog (row) {
      this.requestOrderStatus(row._id, 1)
    },
    async requestOrderStatus (id, status) {
      const res = await request.appealOrderDetail({
        appeal_id: id
      })
      if (res && res.Code === 200) {
        this.dialogItem = res.Data
        if (status === 1) {
          this.dialogaReview = true
        } else {
          this.dialogaDetail = true
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.handleinput>input {
  text-align: start;
  vertical-align: start;
  /* height: 150px; */
  line-height: 30px;
}

// .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// }
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.imagediv {
  width: 100%;
  height: 180px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: start;
}

.imgprove {
  display: flex;
  flex: 1;
  object-fit: cover;
  margin-right: 1px;
  margin-right: 15px;
}

.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
</style>
